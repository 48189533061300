export const environment = {
  production: true,
  hmr: false,
  version: '1.0.0-dev',
  serverUrl: 'https://pso-extranet-api.staging.wchfs.pl/api/v1', // Due to corporate proxy setup
  authUrl: 'https://pso-extranet-api.staging.wchfs.pl',
  assetsUrl: '/assets',
  clientId: 1,
  clientSecret: 'kMoAjDVtngsWwDwKUrtC79MjDgzQCAar3NJTNjnL',
  defaultLanguage: 'pl-PL',
  supportedLanguages: ['pl-PL', 'en-EN'],
  assetUrl: '/assets',
  userSnap: '//api.usersnap.com/load/71486bcb-3ed9-4056-995e-af1ddecb6ad7.js',
  pusher: {
    host: 'pso-extranet-api.staging.wchfs.pl',
    port: '6001',
    key: 'key-pso-staging',
    authEndpoint: 'https://pso-extranet-api.staging.wchfs.pl/broadcasting/auth',
    cluster: 'eu',
  },
  logWebsocketEvents: true,
  logIntervals: false,
  showLegacyPages: true,
  showLibraryPages: true,
  hotjar: false,
  showSentryDialog: true,
  sentryEnvironment: false,
  serviceWorker: true,
  google_forms_survey_id_workshop: '1FAIpQLScp0oPTofh_2XjWU5529GBlWs59XTIjMZ4HwDCbZ626kp2tCw',
  google_forms_field_id_workshop: 'entry.873996089',
  google_forms_widget_text_workshop: 'Wypełnij ankietę w zakresie systemu flotowego Extranet i odbierz nagrody!',
  google_forms_survey_id_afl: '1FAIpQLSetZknTwPMamrVe7b9qKb7sJ9h2KqlG9OvLomC7o68oJsRvHg',
  google_forms_field_id_afl: 'entry.1735162991',
  google_forms_widget_text_afl: 'Wypełnij ankietę w zakresie systemu flotowego Extranet i odbierz nagrody!',
  showWymianaOponWidget: true,
  workshopOfflineNotificationsIntervalInSeconds: 10,
};
